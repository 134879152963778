import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import moment from 'moment/min/moment-with-locales';


import usePalette from "../../../hooks/usePalette";
import McoBillingLineChart from "./charts/McoBillingLineChart";

//openApi connection:
import { OpenAPI, SummaryService } from "../../../services/openapi"; //, OpenAPI, OpenAPIConfig
import McoToken from "../../../components/McoToken";
import { useSelectDate } from "../../../hooks/useSelectDate";
import McoTotalCard from "../../../components/mco/components/McoTotalCard";
import DateSelector from "../../../components/mco/components/DateSelector";


const McoDashboard = () => {
  const palette = usePalette();

  const [
    currentMonth,
    handleCurrentMonth,
    selectValues,
  ] = useSelectDate();


  const { summaryMain, summaryMainCharts } = SummaryService;
  const [ isLoading, setIsLoading ] = useState( true );
  const [ billingData, setBillingData ] = useState({
    labels: [],
    datasets:[
      {
        label: "Monto Facturado ($)",
        data: [],
        fill: false,
        backgroundColor: "transparent",
        borderColor: palette["primary-dark"],
      },
      {
        label: "Monto Cancelado ($)",
        data: [],
        fill: false,
        backgroundColor: "transparent",
        borderColor: palette.danger,
      },
      {
        label: "Suma total",
        fill: false,
        data: [],
        backgroundColor: "transparent",
        borderColor: palette["gray-400"],
        borderDash: [4, 4],
        pointStyles: 'triangle',
      },
    ]
  });

  const [ ordersChartData, setOrdersChartData ] = useState({
    labels:[],
    datasets:[
      {
        label: "Ordenes Completadas",
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette["primary-dark"],
        data: [],
      },
      {
        label: "Ordenes Canceladas",
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette.danger,
        data: [],
      },
      {
        label: "Suma total",
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette["gray-400"],
        borderDash: [4, 4],
        data: [],
      },
    ]
  });

  // summaryMain
  const [ mcoData, setResultData ] = useState(
    {
      main_month:
      {
          net_sale: 0,
          total_orders: 0,
          average_ticket: 0,
          total_units_sold: 0,
          new_customers: 0,
          distinct_customers: 0,
          used_coupons: 0,
          customers_by_companies: [],
          "dates-between":
          {
            "from_date": "",
            "to_date": ""
          }
      },
      prev_month:
      {
          net_sale: 0,
          total_orders: 0,
          average_ticket: 0,
          total_units_sold: 0,
          new_customers: 0,
          distinct_customers: 0,
          used_coupons: 0,
          customers_by_companies: [],
          "dates-between":
          {
            "from_date": "",
            "to_date": ""
          }
      }
    }
  );


  useEffect( () => {

    // get Main data:
    const fetchMainData = async () => {
      OpenAPI.TOKEN = McoToken();
      setIsLoading(true);
      summaryMain( currentMonth )
      .then( results  => {
         const { data, success } = results;
         
        if( success ){
          setResultData( data );
          setIsLoading(false);
        }else {
          return;
        }
      }).catch(error => console.log(error));
    };

    // get Main data for charts:
    const fetchMainChartData = async () => {
      OpenAPI.TOKEN = McoToken();
      summaryMainCharts( currentMonth )
      .then( ({ data, success } )  => {
 
        if( success ){

          let months = Object.keys( data ).map(month => {
            return moment(month)
              .locale("es")
              .format("MMM")
              .replace(".", "");
          }).reverse();

          let values = Object.values( data );

          //MONTO FACTURADO
          let billing_amounts = values.map( elm =>  parseFloat(elm.completed_orders.total_amount)).reverse();

          //MONTO CANCELADO
          let billing_amounts_cancelled = values.map( elm => {
            let n = parseFloat(elm.canceled_orders.total_canceled_amount) || 0;
            if( n.toString().includes("e+") ){
              n = parseFloat(n.toString().replace("e+", "")).toFixed(2);
            }
            return n;
          }).reverse();

          //MOTNO TOTAL: (SI SE SUMA EL MONTO CANCELADO AL FACTURADO).
          let billing_amounts_total = billing_amounts.map((e, index) => {
            return parseFloat(e) + parseFloat(billing_amounts_cancelled[index]);
          });
          
          //ORDENES COMPLETADAS
          let orders_completed = values.map( elm => parseInt(elm.completed_orders.total_orders) ).reverse();

          //ORDENES CANCELADAS
          let orders_cancelled = values.map( elm => parseInt(elm.canceled_orders.total_canceled_orders)).reverse();

          //ORDENES TOTALES
          let orders_qty_total = orders_completed.map( (elm, i) => { return parseInt(elm) + parseInt(orders_cancelled[i]); });

          // Charts for Billing amounts
          setBillingData({
            ...billingData,
            labels: months,
            datasets:[
              {
                ...billingData.datasets[0],
                data: billing_amounts,
              },
              {
                ...billingData.datasets[1],
                data: billing_amounts_cancelled,
              },
              {
                ...billingData.datasets[2],
                data: billing_amounts_total,
              }
            ]
          });

          // Charts for quantity orders
          setOrdersChartData({
            ...ordersChartData,
            labels: months,
            datasets:[
            {
              ...ordersChartData.datasets[0],
              data: orders_completed,
            },
            {
              ...ordersChartData.datasets[1],
              data: orders_cancelled,
            },
            {
              ...ordersChartData.datasets[2],
              data: orders_qty_total,
            }
          ]});

        }else {
          return;
        }
      }).catch(error => console.log(error));
    };

    fetchMainData();
    fetchMainChartData();
  }, [ currentMonth ] );

  const { prev_month } = mcoData;
  const strMonthSelected = ( prev_month['dates-between'].from_date )?  moment( prev_month['dates-between'].from_date )
    .locale("es")
    .format("MMMM") : "";

  return (
    <React.Fragment>
      <Helmet title="Scorecard" />
      <Container fluid className="p-0 mco-fade-in">
        {/*<Header />*/}
        <Row className="mb-2 mb-xl-3 d-flex justify-content-between">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>Scorecard</h3>
          </Col>
          <DateSelector 
            selectValues={selectValues}
            handleCurrentMonth={handleCurrentMonth}
          />
        </Row>
        <Row className="mco-fade-in">
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Venta Neta"
              qty={ parseInt(mcoData.main_month.net_sale)|| 0 }
              prevqty={ mcoData.prev_month.net_sale || 0 }
              subtitle={strMonthSelected}
              icon="ShoppingBag"
              prefix="$"
              format={true}
              is_loading={isLoading} />
          </Col>
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Ordenes Completas"
              qty={ mcoData.main_month.total_orders || 0 }
              prevqty={ mcoData.prev_month.total_orders || 0 }
              subtitle={strMonthSelected}
              icon="ShoppingCart"
              prefix=""
              format={true}
              is_loading={isLoading} />
          </Col>
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Unidades Vendidas"
              qty={ mcoData.main_month.total_units_sold || 0 }
              prevqty={ mcoData.prev_month.total_units_sold || 0 }
              subtitle={strMonthSelected}
              icon="Box"
              prefix=""
              format={true}
              is_loading={isLoading} />
          </Col>
        </Row>

        <Row>
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Ticket Promedio"
              qty={ parseInt(mcoData.main_month.average_ticket) || 0 }
              prevqty={ mcoData.prev_month.average_ticket || 0 }
              subtitle={strMonthSelected}
              icon="ShoppingCart"
              prefix="$"
              format={true}
              is_loading={isLoading} />
          </Col>
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Usuarios Registrados"
              qty={ mcoData.main_month.new_customers || 0 }
              prevqty={ mcoData.prev_month.new_customers || 0 }
              subtitle={strMonthSelected}
              icon="UserPlus"
              prefix=""
              format={true}
              is_loading={isLoading} />
          </Col>
          <Col sm="4" md="4" lg="4" xl="4" xxl="4" className="d-flex">
            <McoTotalCard
              title="Compradores Únicos"
              qty={ mcoData.main_month.distinct_customers || 0 }
              prevqty={ mcoData.prev_month.distinct_customers || 0 }
              subtitle={strMonthSelected}
              icon="User"
              prefix=""
              format={true}
              is_loading={isLoading} />
          </Col>
        </Row>
        <Row className="mt-3">
          <h3>Gráficas en función de facturación y órdenes</h3>
          <Col lg="6" className="mt-2">
            <McoBillingLineChart
              data={billingData}
              title="Facturación de órdenes"
              subtitle="Comparativa de facturación de órdenes completadas vs. canceladas (en los últimos 12 meses)"
              prefix="$" />
          </Col>
          <Col lg="6" className="mt-2">
            <McoBillingLineChart
              data={ordersChartData}
              title="Cantidades de órdenes"
              subtitle="Cantidad de ordenes completadas vs. canceladas (en los últimos 12 meses)" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default McoDashboard;
