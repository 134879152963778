import React from "react";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";
import { locale } from "moment";
import { triggerDateSelect } from "@fullcalendar/react";

const McoBillingLineChart = ({data, title, subtitle }) => {
  const labels   = data.labels.map((word) => {
    let lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }); // Capitalize all monthes

  const options = {
    radius:3,
    responsive: true,
    drawActiveElementsOnTop:true,
    maintainAspectRatio: false,
    tension: 0.4,
    pointRadius: 4,
    pointRotation: 5,
    spanGaps: true,
    hoverBorderWidth: 6,
    onHover: (e, chartElement) => {
      if(chartElement.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
    },
    animations:{
      tension:{
        easing:'linear',
      }
    },
    displayColors: true,
    hover: {
      intersect: true,
    },
    plugins: {
      tooltip: {
        enabled: true,
        intersect: true,
        callbacks: {
          label: function (tooltipItem) {
            const { formattedValue, dataset, raw } = tooltipItem;
          
            return (tooltipItem && dataset.label +': ' + (raw).toLocaleString(locale, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }));
          }
        }
      },
      legend:{
        position: 'bottom',
        labels:{
          usePointStyle: true,
          pointBorderWidth: 10
        }
      },
      filler: {
        propagate: false,
      },
    },
    scales: {
      x: 
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      y: 
        {
          
          display: true,
          borderDash: [5, 5],
          grid:{
            display: false
          },
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      
    },
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {title}
        </Card.Title>
        <span>{subtitle}</span>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};
export default McoBillingLineChart;
